import Container from 'components/ContainerHome';
import LastNews from './components/LastNews';

import styles from './BetHome.module.css';

const BetHome = () => (
  <div className={styles.wrapperBetHome}>
    <Container />

    <LastNews />
  </div>
);

export default BetHome;
