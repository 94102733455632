import { useNavigate } from 'react-router-dom';
import cln from 'classnames';

import { ReactComponent as CrossSvg } from 'assets/images/base/cross.svg';
import { ReactComponent as LogoSvg } from 'assets/images/base/logo.svg';
import Text from 'components/Text';

import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { EModalTypes } from 'stores/modal/types';
import { DROPDOWN_MENU, NAV_LINKS } from 'components/NavBar/constants';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getLanguagesInfoSelector } from 'stores/configuration/selectors';
import { DropDownType } from 'types/header/dropdowns';
import { useZustandLanguagesStore } from 'stores/languages/store';
import {
  getActiveLanguageNameSelector,
  getAndSetLanguagesAction,
} from 'stores/languages/selectors';

import styles from './Burger.module.css';

const BurgerMenu = () => {
  const languages = useZustandWebConfiguration(getLanguagesInfoSelector) || [];

  const activeLanguage =
    useZustandLanguagesStore(getActiveLanguageNameSelector) || '';

  const setLanguage = useZustandLanguagesStore(getAndSetLanguagesAction);

  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const navigate = useNavigate();

  const handleClose = () => {
    closeAndOpen(EModalTypes.Unknown);
  };

  const handleChangeLanguage = (shortName: string) => () => {
    setLanguage(shortName);
  };

  const jumpTo = (path: string | undefined) => () => {
    if (path) navigate(path);
  };

  return (
    <div className={styles.wrapperBurgerMenu}>
      <div className={styles.header}>
        <LogoSvg />
        <CrossSvg onClick={handleClose} className={styles.closeSvg} />
      </div>

      <div className={styles.nav}>
        {DROPDOWN_MENU.map(({ title, items }: DropDownType, idx) => (
          <div key={idx} className={styles.products}>
            <div className={styles.text}>
              <Text idT={title} />
            </div>

            {items.map(({ name, icon, path }, idx) => (
              <div key={idx} className={styles.link} onClick={jumpTo(path)}>
                {icon && <img src={icon} alt="" className={styles.icon} />}

                <Text idT={name} />
              </div>
            ))}
          </div>
        ))}

        {NAV_LINKS.map(({ name, path }, idx) => (
          <div key={idx} className={styles.text} onClick={jumpTo(path)}>
            <Text idT={name} />
          </div>
        ))}
      </div>

      <div className={styles.languages}>
        {languages.map(({ shortName, name, icon }, idx) => (
          <div
            key={idx}
            className={styles.language}
            onClick={handleChangeLanguage(shortName)}
          >
            {shortName === activeLanguage && (
              <img src={icon} alt="" className={styles.icon} />
            )}
            <div
              className={cln({
                [styles.active]: shortName === activeLanguage,
              })}
            >
              <Text idT={name} />
            </div>
          </div>
        ))}
      </div>

      <div className={styles.row}>
        <div className={styles.rowInfo}>
          <Text idT="address" />
        </div>

        <div className={styles.rowInfo}>
          <Text idT="copyright" />
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
