import parse from 'html-react-parser';

import { getSpecTranslateKeysSelector } from 'stores/configuration/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getLanguageDictionarySelector } from 'stores/languages/selectors';
import { useZustandLanguagesStore } from 'stores/languages/store';

export default function useTranslate(name: string, specValue: string) {
  const dictionary = useZustandLanguagesStore(getLanguageDictionarySelector);

  const specKeys = useZustandWebConfiguration(getSpecTranslateKeysSelector);

  const getText = (
    path: string,
    obj = dictionary,
    separator = '.',
    specValue = '',
  ) => {
    try {
      const properties = path?.split(separator);

      let result =
        properties.reduce((prev: any, curr: any) => prev && prev[curr], obj) ||
        path;

      result = result.replaceAll('[BRAND_NAME]', 'KBet');

      if (result.includes('[INJECT_VALUE]')) {
        result = result
          .replaceAll('[INJECT_VALUE]', '')
          .replaceAll('[VALUE]', specValue);
      }

      if (result?.includes('[HTML]')) {
        const text = result.replace('[HTML]', '');

        result = parse(text) || text;
      }

      if (specKeys) {
        Object.keys(specKeys).forEach((key) => {
          if (typeof result === 'string') {
            result = result.replaceAll(key, specKeys[key]);
          }
        });
      }

      return result;
    } catch (error) {
      return path || '';
    }
  };

  return {
    getText,
    name: getText(name, undefined, undefined, specValue),
  };
}
