export const FOOTER_COMPANY_LINKS = [
  {
    text: 'about',
    path: '',
  },
  {
    text: 'blog',
    path: '',
  },
  {
    text: 'careers',
    path: '',
  },
];

export const FOOTER_PRODUCTS_LINKS = [
  {
    text: 'sportsbook',
    path: '',
  },
  {
    text: 'KBetGames',
    path: '',
  },
  {
    text: 'virtualSports',
    path: '',
  },
  {
    text: 'esports',
    path: '',
  },
  {
    text: 'aiLabs',
    path: '',
  },
];

export const FOOTER_CONTACT_LINKS = [
  {
    text: 'mail',
    path: '',
  },
  {
    text: 'contactUs',
    path: '',
  },
  {
    text: 'linkedIn',
    path: '',
  },
];

export const FOOTER_DOCUMENTS_LINKS = [
  {
    text: 'privacy',
    path: '',
  },
  {
    text: 'termsAndConditions',
    path: '',
  },
];
