import cln from 'classnames';
import { useState } from 'react';

import { ReactComponent as ArrowSvg } from 'assets/images/base/arrow.svg';
import Text from 'components/Text';
import { LanguageInfo } from 'stores/configuration/types';

import styles from './Selector.module.css';

type Props = {
  data: LanguageInfo[];
  activeLang: string;
  handleClick?: (info: string) => void;
};

const Selector = (props: Props) => {
  const { data, activeLang, handleClick } = props;

  const [isOpened, setOpenState] = useState<boolean>(false);

  const activeInfo = data.find((item) => item.shortName === activeLang) || null;

  const handleChangeInfo = (shortName: string) => () => {
    setOpenState(false);

    handleClick?.(shortName);
  };

  const sortedData = data.slice().sort((a, b) => {
    if (a.name === activeInfo?.name) return -1;

    if (b.name === activeInfo?.name) return 1;

    return 0;
  });

  return (
    <div
      className={styles.wrapperSelector}
      onMouseEnter={() => setOpenState(true)}
      onMouseLeave={() => setOpenState(false)}
    >
      {activeInfo?.icon && (
        <img src={activeInfo.icon} alt="" className={styles.icon} />
      )}
      <span className={styles.text}>{activeInfo?.name}</span>

      <ArrowSvg className={cln(styles.arrowSvg, styles.icon)} />

      <div className={styles.selectorContent}>
        <div
          className={cln(styles.selectorMenu, {
            [styles.show]: isOpened,
          })}
        >
          {isOpened &&
            sortedData.map(({ shortName, name, icon }, idx) => (
              <div
                key={idx}
                onClick={handleChangeInfo(shortName)}
                className={styles.selectorMenuLink}
              >
                {icon && <img src={icon} alt="" className={styles.icon} />}

                <span className={styles.text}>
                  <Text idT={name} />
                </span>

                {!idx && (
                  <ArrowSvg className={cln(styles.arrowSvg, styles.icon)} />
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default Selector;
