import { NavLink } from 'react-router-dom';

import DropDown from 'components/ui/DropDown';
import Text from 'components/Text';

import { DROPDOWN_MENU, NAV_LINKS } from './constants';

import styles from './NavBar.module.css';

const NavBar = () => (
  <div className={styles.wrapperNavBar}>
    <DropDown data={DROPDOWN_MENU} />

    {NAV_LINKS.map(({ name, path }, idx) => (
      <NavLink key={idx} to={path} className={styles.link}>
        <Text idT={name} />
      </NavLink>
    ))}
  </div>
);

export default NavBar;
