import cln from 'classnames';

import { ReactComponent as LogoSvg } from 'assets/images/base/logo.svg';
import { ReactComponent as BurgerSvg } from 'assets/images/base/burger.svg';
import ButtonBase from 'components/ui/Buttons/ButtonBase';
import NavBar from 'components/NavBar';
import Languages from 'components/Languages';

import { EHEADER_UI } from 'types/header/ui';
import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { EModalTypes } from 'stores/modal/types';

import styles from './Header.module.css';

type Props = {
  type?: EHEADER_UI;
};

const Header = ({ type }: Props) => {
  const setModal = useZustandModalStore(setModalInfoAction);

  const openModal = () => {
    setModal(EModalTypes.BurgerMenu);
  };

  return (
    <div
      className={cln(styles.wrapperHeader, {
        [styles.blobs]: type,
      })}
    >
      <LogoSvg className={styles.logo} />

      <NavBar />

      <div className={styles.leftContent}>
        <Languages />

        {type === EHEADER_UI.Sticky && (
          <ButtonBase
            text="demo"
            type="colorful"
            size="small"
            handleClick={console.log}
          />
        )}

        <BurgerSvg className={styles.burger} onClick={openModal} />
      </div>
    </div>
  );
};

export default Header;
