export const CLIENTS = [
  'icons-KBet/clients/RollBit-logo.svg',
  'icons-KBet/clients/gamingtec-logo.svg',
  'icons-KBet/clients/blaze-logo.svg',
  'icons-KBet/clients/4rabet-logo.svg',
  'icons-KBet/clients/500casino-logo.svg',
  'icons-KBet/clients/badhombregaming-logo.svg',
  'icons-KBet/clients/monotech.svg',
  'icons-KBet/clients/joycasino-logo.svg',
  'icons-KBet/clients/casinox-logo.svg',
  'icons-KBet/clients/pokerdom-logo.svg',
  'icons-KBet/clients/bons-client.svg',
  'icons-KBet/clients/JackWin-logo.svg',
  'icons-KBet/clients/wearecasino-logo.svg',
  'icons-KBet/clients/mobinc-logo.svg',
  'icons-KBet/clients/sol-logo.svg',
  'icons-KBet/clients/betmaster-logo.svg',
  'icons-KBet/clients/goldenreels-logo.svg',
  'icons-KBet/clients/casitabi.svg',
  'icons-KBet/clients/amuletobet.svg',
  'icons-KBet/clients/advabet-logo.svg',
  'icons-KBet/clients/purewin.svg',
  'icons-KBet/clients/herogaming-logo.svg',
  'icons-KBet/clients/juegaenlinea.svg',
  'icons-KBet/clients/hyperwin-logo.svg',
  'icons-KBet/clients/groove-logo.svg',
  'icons-KBet/clients/BoomCasion-logo.svg',
  'icons-KBet/clients/bilbet.svg',
  'icons-KBet/clients/RocketPot-logo.svg',
  'icons-KBet/clients/BurningBet-logo.svg',
  'icons-KBet/clients/BetFury-logo.svg',
  'icons-KBet/clients/TedBet-logo.svg',
  'icons-KBet/clients/DozenSpins-logo.svg',
  'icons-KBet/clients/SimpleCasino-logo.svg',
  'icons-KBet/clients/cricketbook-logo.svg',
  'icons-KBet/clients/fortunejack-logo.svg',
  'icons-KBet/clients/africa365-logo.svg',
  'icons-KBet/clients/apostaganha-logo.svg',
  'icons-KBet/clients/playing-logo.svg',
];
