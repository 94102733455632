import { useEffect, useState } from 'react';
import cln from 'classnames';

import Header from 'components/Header';

import { EHEADER_UI } from 'types/header/ui';

import styles from './StickyHeader.module.css';

const StickyHeader = () => {
  const [showStickyHeader, setShowStickyHeader] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY || window.pageYOffset;

      if (scrollY >= 50) {
        setShowStickyHeader(true);
      } else {
        setShowStickyHeader(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={cln(styles.wrapperStickyHeader, {
        [styles.show]: showStickyHeader,
      })}
    >
      <Header type={EHEADER_UI.Sticky} />
    </div>
  );
};

export default StickyHeader;
