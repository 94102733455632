import useTranslate from 'hooks/useTranslate';

type Props = {
  idT: string;
  specValue?: string;
};

const Text = ({ idT, specValue = '' }: Props) => {
  const { name } = useTranslate(idT, specValue);

  return <>{name}</>;
};

export default Text;
