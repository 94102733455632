import Text from 'components/Text';

import styles from './Textarea.module.css';

type Props = {
  text: string;
  handleChange?: (data: string) => void;
};

const Textarea = (props: Props) => {
  const { text, handleChange } = props;

  return (
    <label className={styles.wrapperTextarea}>
      <p className={styles.text}>
        <Text idT={text} />
      </p>
      <textarea
        name=""
        id=""
        className={styles.textarea}
        onChange={(e) => handleChange?.(e.target.value)}
      ></textarea>
    </label>
  );
};

export default Textarea;
