import { create } from 'zustand';
import { WebConfiguration, ZustandConfigurationStore } from './types';
import { getFromLocalStorage } from 'utils/localstorage';
import { CUSTOM_SOCKET_NAME } from 'constants/localstoreKeys';

export const useZustandWebConfiguration = create<ZustandConfigurationStore>(
  (set) => ({
    webConfiguration: null,

    getWebConfiguration: async () => {
      try {
        const root = document.querySelector<HTMLElement>(':root');

        if (!root) {
          throw new Error('No root element');
        }

        const responseWeb = await fetch(
          `${window.location.origin}/configs/web.json`,
          {
            method: 'GET',
            cache: 'no-cache',
          },
        );

        const webConfiguration =
          (await responseWeb.json()) satisfies WebConfiguration;

        if (!webConfiguration.transport.address) {
          webConfiguration.transport.address = `wss://wss.${window.location.host}/`;
        }
        const customSocketAddress = getFromLocalStorage(CUSTOM_SOCKET_NAME);

        if (customSocketAddress) {
          webConfiguration.transport.address = customSocketAddress;
        }

        set({ webConfiguration });
      } catch (error) {
        console.error(error, 'CRITICAL ERROR');
      }
    },
  }),
);
