import Selector from 'components/ui/Selector';

import { useZustandLanguagesStore } from 'stores/languages/store';
import {
  getActiveLanguageNameSelector,
  getAndSetLanguagesAction,
} from 'stores/languages/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getLanguagesInfoSelector } from 'stores/configuration/selectors';

import styles from './Languages.module.css';

const Languages = () => {
  const languages = useZustandWebConfiguration(getLanguagesInfoSelector) || [];

  const activeLanguage =
    useZustandLanguagesStore(getActiveLanguageNameSelector) || '';

  const setLanguage = useZustandLanguagesStore(getAndSetLanguagesAction);

  const handleChangeLanguage = (lang: string) => {
    if (lang) setLanguage(lang);
  };

  return (
    <div className={styles.wrapperLanguages}>
      <Selector
        data={languages}
        activeLang={activeLanguage}
        handleClick={handleChangeLanguage}
      />
    </div>
  );
};

export default Languages;
