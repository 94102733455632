import cln from 'classnames';

import Text from 'components/Text';
import { ReactComponent as ProductGamesSvg } from 'assets/images/container/product-games-icon.svg';

import { CARDS_IMAGES, CARDS_VIDEOS } from './constants';

import styles from './Cards.module.css';

const Cards = () => (
  <div className={styles.wrapperCards}>
    {CARDS_IMAGES.map(({ title, subtitle, media, desk }, idx) => (
      <div key={idx} className={styles.card}>
        <div className={styles.title}>
          <Text idT={title} />
        </div>
        <div className={styles.subtitle}>
          <Text idT={subtitle} />
        </div>

        <img
          src={window.innerWidth >= 1200 ? media : desk || media}
          alt=""
          className={cln(styles.image, styles[`image-${idx + 1}`])}
        />

        {!idx && <div className={styles.leftBlob}></div>}
        <div
          className={cln(styles.blob, {
            [styles.firstCardBlob]: !idx,
          })}
        ></div>
      </div>
    ))}

    {CARDS_VIDEOS.map(({ title, subtitle, media }, idx) => (
      <div
        key={idx}
        className={cln(styles.card, styles.videoContainer, {
          [styles.center]: !idx,
        })}
      >
        {!idx && <ProductGamesSvg className={styles.svg} />}
        <div className={styles.title}>
          <Text idT={title} />
        </div>
        <div className={styles.subtitle}>
          <Text idT={subtitle} />
        </div>

        <div className={styles.videoBox}>
          <video autoPlay loop playsInline muted className={styles.video}>
            <source src={media} type="video/mp4" />
          </video>
        </div>

        {idx && <div className={styles.blur}></div>}
      </div>
    ))}
  </div>
);

export default Cards;
