export interface ZustandModalStore extends ZustandModalEntities {
  setModalInfo: (type: EModalTypes) => void;

  closeAndOpen: (type: EModalTypes) => void;
}

interface ZustandModalEntities {
  modalType: EModalTypes;
  oldType: EModalTypes | null;
}

export enum EModalTypes {
  Unknown,
  CloseModal,

  BurgerMenu,
  FeedbackForm,
}
