import FeedbackButton from './components/FeedbackButton';

import { useZustandModalStore } from 'stores/modal/store';
import { setModalInfoAction } from 'stores/modal/selectors';
import { EModalTypes } from 'stores/modal/types';

import styles from './FeedbackContainer.module.css';

const FeedbackContainer = () => {
  const setModal = useZustandModalStore(setModalInfoAction);

  const handleSetBattleType = () => {
    setModal(EModalTypes.FeedbackForm);
  };

  return (
    <div className={styles.wrapperFeedbackContainer}>
      <FeedbackButton handleClick={handleSetBattleType} />
    </div>
  );
};

export default FeedbackContainer;
