export type TEmail = {
  errorText?: string;
  value: string;
};

export type DefaultInputType = {
  value: string;
  errorText: string;
};

export const DEFAULT_INPUT_VALUE: DefaultInputType = {
  value: '',
  errorText: '',
};

export const hasLocalPart =
  ({ value, errorText }: TEmail) =>
  () => ({
    value,
    errorText: value.includes('@')
      ? ''
      : errorText || 'Email address must include the @ symbol',
  });

export const hasDomainPart =
  ({ value, errorText }: TEmail) =>
  () => ({
    value,
    errorText: value.match(/^[^\s@]+@([^\s@]+\.[^\s@]+)$/)
      ? ''
      : errorText || 'Email address is not valid',
  });

type ValidationFunction = () => { errorText: string };

export const composeValidations = (fns: ValidationFunction[]): string => {
  const results: { errorText: string }[] = fns.map((f) => f());
  const errorResult = results.find((result) => result.errorText);

  return errorResult ? errorResult.errorText : '';
};
