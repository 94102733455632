import { CLIENTS } from './constants';

import styles from './Clients.module.css';

const Clients = () => (
  <div className={styles.wrapperClients}>
    {CLIENTS.map((path, idx) => (
      <img src={path} alt="" key={idx} className={styles.image} />
    ))}
  </div>
);

export default Clients;
