import styles from './FeedbackButton.module.css';

type Props = {
  handleClick: () => void;
};

const FeedbackButton = (props: Props) => {
  const { handleClick } = props;

  return (
    <div
      onClick={handleClick}
      className={styles.feedbackButton}
      style={{
        backgroundImage: 'url(/icons-KBet/base/chat.svg)',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    ></div>
  );
};

export default FeedbackButton;
