import Header from 'components/Header';
import HomePage from 'pages/HomePage';

import styles from './ContainerHome.module.css';

const ContainerHome = () => (
  <div className={styles.wrapperContainerHome}>
    <Header />

    <HomePage />
  </div>
);

export default ContainerHome;
