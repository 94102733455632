import ButtonBase from 'components/ui/Buttons/ButtonBase';
import News from './components/News';
import { LAST_NEWS } from './lastNews';

import styles from './LastNews.module.css';

const LastNews = () => (
  <div className={styles.wrapperLastNews}>
    <div className={styles.lastNews}>
      {LAST_NEWS.map((data, idx) => (
        <News {...data} key={idx} />
      ))}
    </div>

    <ButtonBase
      text="news.allNews"
      handleClick={console.log}
      classes={styles.showAllButton}
      type="transparent"
      size="medium"
    />
  </div>
);

export default LastNews;
