export const NAV_LINKS = [
  {
    name: 'about',
    path: '/about',
  },
  {
    name: 'blog',
    path: '/blog',
  },
  {
    name: 'careers',
    path: '/careers',
  },
];

export const DROPDOWN_MENU = [
  {
    title: 'products',
    items: [
      {
        name: 'sportsbook',
        path: '/sportsbook/',
      },
      {
        name: 'KBetGames',
        path: '/games/',
      },
      {
        name: 'virtualSports',
        path: '/virtual/',
      },
      {
        name: 'esports',
        path: '/e-sports/',
      },
      {
        name: 'aiLabs',
        path: '/e-sports/',
      },
    ],
  },
];
