import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import cln from 'classnames';

import BurgerMenu from './components/Burger';
import FeedbackForm from './components/FeedbackContainer/components/FeedbackForm';

import {
  getModalTypeSelector,
  getNextModalTypeSelector,
} from 'stores/modal/selectors';
import { useZustandModalStore } from 'stores/modal/store';
import { EModalTypes } from 'stores/modal/types';

import styles from './Modal.module.css';

const portal = document.getElementsByTagName('body')[0];

const Modal = () => {
  const modalType = useZustandModalStore(getModalTypeSelector);

  const oldModalType = useZustandModalStore(getNextModalTypeSelector);

  const prevType = useRef<EModalTypes | null>(null);

  const isModal =
    modalType !== EModalTypes.Unknown && modalType !== EModalTypes.CloseModal;

  const isCloseModal = modalType === EModalTypes.CloseModal && prevType.current;

  const getModalComponent = () => {
    const key =
      modalType === EModalTypes.CloseModal ? prevType.current : modalType;

    switch (key) {
      case EModalTypes.BurgerMenu:
        return <BurgerMenu />;
      case EModalTypes.FeedbackForm:
        return <FeedbackForm />;
      default:
        return null;
    }
  };

  useEffect(() => {
    if (oldModalType) {
      prevType.current = oldModalType;
    }
  }, [oldModalType]);

  useEffect(() => {
    const isModal = modalType !== EModalTypes.Unknown && !isCloseModal;

    if (isModal) {
      document.body.style.overflow = 'hidden';

      return;
    }

    document.body.style.overflow = 'unset';
  }, [modalType]);

  return portal
    ? ReactDOM.createPortal(
        <>
          <div
            className={cln(styles.wrapperModal, {
              [styles.visible]: isModal,
              [styles.animationFadeOutModal]: isCloseModal,
              [styles.animationSmallFadeOutModal]:
                isCloseModal && prevType.current === EModalTypes.FeedbackForm,
              [styles.small]: modalType === EModalTypes.FeedbackForm,
            })}
          >
            {getModalComponent()}
          </div>
        </>,
        portal,
      )
    : null;
};

export default Modal;
