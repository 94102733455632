import Awards from 'components/Awards';
import ButtonBase from 'components/ui/Buttons/ButtonBase';
import Text from 'components/Text';
import Cards from 'components/Cards';
import { ReactComponent as VectorArrowSvg } from 'assets/images/base/vectorArrow.svg';
import { ReactComponent as PlaySvg } from 'assets/images/container/play.svg';

import styles from './HomePage.module.css';

const HomePage = () => (
  <div className={styles.wrapperHomePage}>
    <div className={styles.block}>
      <div className={styles.title}>
        <Text idT="home.futureBetting" />
      </div>

      <div className={styles.subtitle}>
        <Text idT="home.focus" />
      </div>

      <div className={styles.buttons}>
        <ButtonBase
          text="home.tryDemo"
          type="colorful"
          size="medium"
          handleClick={console.log}
          endIcon={<VectorArrowSvg />}
          classes={styles.demoButton}
        />

        <ButtonBase
          text="home.watchVideo"
          type="transparent-colorful"
          size="medium"
          handleClick={console.log}
          iconText={<PlaySvg />}
          classes={styles.watchVideoButton}
        />
      </div>

      <div
        className={styles.imageContainer}
        style={{
          backgroundSize: 'contain',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom',
          backgroundImage: 'url(/public/icons-KBet/home/shadow.png)',
        }}
      >
        <img
          src={
            window.innerWidth > 768
              ? 'icons-KBet/home/sportsbook-desktop.avif'
              : // 'icons-KBet/home/desktop-sloth.png'
                'icons-KBet/home/sportsbook-mobile.avif'
          }
          alt=""
          className={styles.image}
        />
      </div>
    </div>

    <Awards />

    <Cards />
  </div>
);

export default HomePage;
