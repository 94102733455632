import { create } from 'zustand';
import { EModalTypes, ZustandModalStore } from './types';
import { sleep } from 'utils/shared';

export const useZustandModalStore = create<ZustandModalStore>((set, get) => ({
  modalType: EModalTypes.Unknown,
  oldType: null,

  setModalInfo: (modalType) => {
    set({ modalType });
  },
  closeAndOpen: async (type) => {
    const { modalType } = get();

    set({ oldType: modalType });

    await sleep(100);

    set({ modalType: EModalTypes.CloseModal });

    await sleep(500);

    set({ modalType: type });
  },
}));
