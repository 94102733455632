import Text from 'components/Text';

import { PARTNERS } from './constants';

import styles from './Partners.module.css';

const Partners = () => (
  <div>
    <div className={styles.title}>
      <Text idT="usersAmount" specValue="1" />
    </div>

    <div className={styles.list}>
      {PARTNERS.map((path, idx) => (
        <img src={path} alt="" key={idx} className={styles.image} />
      ))}
    </div>
  </div>
);

export default Partners;
