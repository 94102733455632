import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import cln from 'classnames';

import { ReactComponent as ArrowSvg } from 'assets/images/base/arrow.svg';
import Text from 'components/Text';

import { DropDownType } from 'types/header/dropdowns';

import styles from './DropDown.module.css';

type Props = {
  data: DropDownType[];
};

const DropDown = (props: Props) => {
  const { data } = props;

  const [isOpen, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleToggleState = (isNeedToBeOpened: boolean) => () => {
    setOpen(isNeedToBeOpened);
  };

  const jumpTo = (path: string | undefined) => () => {
    if (path) navigate(path);
  };

  return (
    <div
      className={cln(styles.dropdown, { [styles.dropdownActive]: isOpen })}
      onMouseEnter={handleToggleState(true)}
      onMouseLeave={handleToggleState(false)}
    >
      <div
        className={cln(styles.dropdownTrigger, {
          [styles.hidden]: isOpen,
        })}
      >
        <span className={styles.dropdownTriggerText}>
          {<Text idT={data[0].title} />}
        </span>
        <ArrowSvg className={styles.arrowSvg} />
      </div>

      <div className={styles.dropdownContent}>
        <div className={styles.dropdownMenu}>
          {isOpen &&
            data.map(({ items }: DropDownType, idx) => (
              <div key={idx}>
                {items.map(({ name, icon, path }, idx) => (
                  <div
                    key={idx}
                    className={styles.dropdownMenuLink}
                    onClick={jumpTo(path)}
                  >
                    {icon && <img src={icon} alt="" className={styles.icon} />}

                    <span>
                      <Text idT={name} />
                    </span>
                  </div>
                ))}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default DropDown;
