import { NavLink } from 'react-router-dom';

import { ReactComponent as MgaSvg } from 'assets/images/base/mga.svg';
import Partners from './components/Partners';
import Clients from './components/Clients';
import Text from 'components/Text';

import {
  FOOTER_COMPANY_LINKS,
  FOOTER_CONTACT_LINKS,
  FOOTER_DOCUMENTS_LINKS,
  FOOTER_PRODUCTS_LINKS,
} from './constants';

import styles from './Footer.module.css';

const Footer = () => (
  <div className={styles.wrapperFooter}>
    <div className={styles.content}>
      <Partners />

      <Clients />

      <div className={styles.mga}>
        <div className={styles.logo}>
          <MgaSvg />
        </div>

        <div className={styles.text}>
          <Text idT="mgaText" />
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.column}>
          {FOOTER_COMPANY_LINKS.map(({ text, path }, idx) => (
            <NavLink to={path} key={idx} className={styles.link}>
              <Text idT={text} />
            </NavLink>
          ))}
        </div>

        <div className={styles.column}>
          {FOOTER_PRODUCTS_LINKS.map(({ text, path }, idx) => (
            <NavLink to={path} key={idx} className={styles.link}>
              <Text idT={text} />
            </NavLink>
          ))}
        </div>

        <div className={styles.column}>
          {FOOTER_CONTACT_LINKS.map(({ text, path }, idx) => (
            <NavLink to={path} key={idx} className={styles.link}>
              <Text idT={text} />
            </NavLink>
          ))}
        </div>

        <div className={styles.column}>
          {FOOTER_DOCUMENTS_LINKS.map(({ text, path }, idx) => (
            <NavLink to={path} key={idx} className={styles.link}>
              <Text idT={text} />
            </NavLink>
          ))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.rowInfo}>
          <Text idT="address" />
        </div>

        <div className={styles.rowInfo}>
          <Text idT="copyright" />
        </div>
      </div>
    </div>
  </div>
);

export default Footer;
