import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';

import App from './App';
import Modal from 'components/Alerts/Modal';

import reportWebVitals from './reportWebVitals';

import './index.css';
import './styles/variables.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <Router>
    <App />
    <Modal />
  </Router>,
);

reportWebVitals();
