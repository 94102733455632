import React, { useEffect, useRef } from 'react';
import InitialRoutes from './routes';
import StickyHeader from 'components/Alerts/StickyHeader';
import Footer from 'components/Footer';
import FeedbackContainer from 'components/Alerts/Modal/components/FeedbackContainer';

import { useZustandLanguagesStore } from 'stores/languages/store';
import { setInitialLanguageAction } from 'stores/languages/selectors';
import { useZustandWebConfiguration } from 'stores/configuration/store';
import { getWebConfigurationAction } from 'stores/configuration/selectors';

const App = () => {
  const getWebConfigurations = useZustandWebConfiguration(
    getWebConfigurationAction,
  );
  const setInitLanguages = useZustandLanguagesStore(setInitialLanguageAction);

  const disableStrickMode = useRef<boolean>(false);

  useEffect(() => {
    if (disableStrickMode.current) {
      return;
    }

    disableStrickMode.current = true;
    getWebConfigurations();

    setInitLanguages();
  }, [getWebConfigurations, setInitLanguages]);

  return (
    <>
      <StickyHeader />

      <InitialRoutes />

      <Footer />

      <FeedbackContainer />
    </>
  );
};

export default App;
