export const CARDS_IMAGES = [
  {
    title: 'sportsbook',
    subtitle: 'home.sportsbookOffer',
    media: 'icons-KBet/home/phone.avif',
    desk: 'icons-KBet/home/website.avif',
  },
  {
    title: 'esports',
    subtitle: 'home.esportsOffer',
    media: 'icons-KBet/home/trophy.avif',
  },
  {
    title: 'virtuals',
    subtitle: 'home.virtualsOffer',
    media: 'icons-KBet/home/horse.avif',
  },
];

export const CARDS_VIDEOS = [
  {
    title: 'KBetGames',
    subtitle: 'home.KBetGamesOffer',
    media: 'https://KBet.com/video/KBetGames.mp4',
  },
  {
    title: 'KBetLabs',
    subtitle: 'home.KBetLabsOffer',
    media: 'https://KBet.com/video/loop-main-page.webm',
  },
];
