import Text from 'components/Text';
import styles from './News.module.css';
import { useNavigate } from 'react-router-dom';

type Props = {
  category: string;
  title: string;
  subtitle: string;
  date: string;
  imgPath: string;
  path: string;
};

const News = (props: Props) => {
  const { category, title, subtitle, date, imgPath, path } = props;

  const navigate = useNavigate();

  const jumpTo = (path: string) => () => {
    navigate(path);
  };

  return (
    <div className={styles.wrapperNews} onClick={jumpTo(path)}>
      <div
        className={styles.image}
        style={{
          backgroundImage: `url(${imgPath})`,
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
        }}
      ></div>

      <div className={styles.text}>
        <div className={styles.category}>
          <Text idT={category} />
        </div>

        <div className={styles.title}>
          <Text idT={title} />
        </div>

        <div className={styles.subtitle}>
          <Text idT={subtitle} />
        </div>

        <div className={styles.postDate}>
          <Text idT={date} />
        </div>
      </div>
    </div>
  );
};

export default News;
