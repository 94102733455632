import cln from 'classnames';

import Text from 'components/Text';

import { Color, Size } from 'types/ui';

import styles from './ButtonBase.module.css';

type Props = {
  type: Color;
  size: Size;
  text: string;
  handleClick: (e: any) => void;
  disabled?: boolean;
  classes?: string;
  iconText?: React.ReactElement;
  endIcon?: React.ReactElement;
};

const ButtonBase = (props: Props) => {
  const {
    text,
    size,
    type,
    disabled = false,
    handleClick,
    classes = undefined,
    iconText,
    endIcon,
  } = props;

  const colorModifier = `wrapperButtonBaseColor-${type}`;

  const borderColorModifier = `wrapperButtonBaseBorder-${type}`;

  const sizeModifier = `wrapperButtonBaseSize-${size}`;

  return (
    <div
      className={cln(
        styles.wrapperButtonBase,
        styles[sizeModifier],
        styles[borderColorModifier],
        styles[colorModifier],
        classes,
        {
          [styles.disabled]: disabled,
        },
      )}
      onClick={handleClick}
    >
      <div className={styles.wrapperText}>
        {iconText && <div className={styles.icon}>{iconText}</div>}

        <Text idT={text} />

        {endIcon && (
          <div className={cln(styles.icon, styles.endIcon)}>{endIcon}</div>
        )}
      </div>
    </div>
  );
};

export default ButtonBase;
