export const LAST_NEWS = [
  {
    category: 'blog',
    title: 'news.cactusGaming',
    subtitle: 'news.cactusGamingDescription',
    date: 'Apr 18, 2024',
    imgPath: 'icons-KBet/news/cactusGaming.jpg',
    path: '',
  },
  {
    category: 'blog',
    title: 'news.esports',
    subtitle: 'news.esportsDescription',
    date: 'Apr 10, 2024',
    imgPath: 'icons-KBet/news/esports.jpg',
    path: '',
  },
  {
    category: 'blog',
    title: 'news.lalaStars',
    subtitle: 'news.lalaStarsDescription',
    date: 'Apr 4, 2024',
    imgPath: 'icons-KBet/news/lalaStars.jpg',
    path: '',
  },
  {
    category: 'news.pdf',
    title: 'news.brazilsSport',
    subtitle: 'news.brazilsSportDescription',
    date: 'Mar 26, 2024',
    imgPath: 'icons-KBet/news/industry.png',
    path: '',
  },
];
