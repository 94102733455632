import { Navigate, Route, Routes } from 'react-router-dom';
import { ERouteNames } from './types';
import BetHome from '../pages/BetHome';

const InitialRoutes = () => (
  <Routes>
    <Route path={ERouteNames.Home} element={<BetHome />} />

    <Route path="*" element={<Navigate to={ERouteNames.Home} replace />} />
  </Routes>
);

export default InitialRoutes;
