import cln from 'classnames';

import Text from 'components/Text';

import styles from './Input.module.css';

type Props = {
  text: string;
  type?: string;
  handleChange?: (data: string) => void;
  errorText?: string;
};

const Input = (props: Props) => {
  const { text, type = 'text', handleChange, errorText } = props;

  return (
    <label className={styles.wrapperInput}>
      <p className={styles.text}>
        <Text idT={text} />
      </p>
      <input
        type={type}
        className={cln(styles.input, {
          [styles.invalid]: errorText,
        })}
        onChange={(e) => handleChange?.(e.target.value)}
      />

      {errorText && (
        <div className={styles.invalidText}>
          <Text idT={errorText} />
        </div>
      )}
    </label>
  );
};

export default Input;
