import { ZustandConfigurationStore } from './types';

export const getWebConfigurationSelector = (state: ZustandConfigurationStore) =>
  state.webConfiguration;

export const getLanguagesForSelectorsSelector = (
  state: ZustandConfigurationStore,
) =>
  getLanguagesInfoSelector(state)?.map((l, inx) => ({
    label: l.name,
    value: +inx,
  })) || [];

export const getLanguagesInfoSelector = (state: ZustandConfigurationStore) =>
  getWebConfigurationSelector(state)?.languages;

export const getSpecTranslateKeysSelector = (
  state: ZustandConfigurationStore,
) => getWebConfigurationSelector(state)?.translateSpecialKeys;

export const getWebConfigurationAction = (state: ZustandConfigurationStore) =>
  state.getWebConfiguration;
