import { useState } from 'react';

import { ReactComponent as CrossSvg } from 'assets/images/base/cross.svg';
import Text from 'components/Text';
import Input from 'components/ui/Input';
import ButtonBase from 'components/ui/Buttons/ButtonBase';
import Textarea from 'components/ui/Textarea';

import {
  DEFAULT_INPUT_VALUE,
  DefaultInputType,
  composeValidations,
  hasLocalPart,
  hasDomainPart,
} from 'types/validation';
import { useZustandModalStore } from 'stores/modal/store';
import { closeAndOpenModalAction } from 'stores/modal/selectors';
import { EModalTypes } from 'stores/modal/types';

import styles from './FeedbackForm.module.css';

const FeedbackForm = () => {
  const closeAndOpen = useZustandModalStore(closeAndOpenModalAction);

  const handleSetBattleType = () => {
    closeAndOpen(EModalTypes.Unknown);
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState<DefaultInputType>(DEFAULT_INPUT_VALUE);
  const [message, setMessage] = useState('');

  const isFormValid = email.value && !email.errorText && name && message;

  const handleSetValue = (value: string) => {
    const errorText = composeValidations([
      hasLocalPart({ value, errorText: 'feedback.errorEmail' }),
      hasDomainPart({ value, errorText: 'feedback.errorDomainEmail' }),
    ]);

    setEmail({ value, errorText });

    if (!value) {
      setEmail({ value: '', errorText: '' });

      return;
    }
  };

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeMessage = (value: string) => {
    setMessage(value);
  };

  return (
    <div className={styles.feedbackForm}>
      <form action="" className={styles.form}>
        <div className={styles.title}>
          <span>
            <Text idT="feedback.generalInquiries" />
          </span>
          <CrossSvg onClick={handleSetBattleType} className={styles.close} />
        </div>

        <Input text="feedback.name" handleChange={handleChangeName} />

        <Input
          text="feedback.email"
          type="email"
          handleChange={handleSetValue}
          errorText={email.errorText}
        />

        <Textarea text="feedback.message" handleChange={handleChangeMessage} />

        <ButtonBase
          text="feedback.sendMessage"
          size="full"
          type="colorful"
          handleClick={console.log}
          disabled={!isFormValid}
        />

        <div className={styles.text}>
          <Text idT="feedback.protectedSite" />
        </div>
      </form>
    </div>
  );
};

export default FeedbackForm;
