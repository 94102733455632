import ButtonBase from 'components/ui/Buttons/ButtonBase';
import { ReactComponent as VectorArrowSvg } from 'assets/images/base/vectorArrow.svg';

import { AWARDS } from './constants';

import styles from './Awards.module.css';

const Awards = () => (
  <div className={styles.wrapperAwards}>
    <div className={styles.awards}>
      {AWARDS.map((award, idx) => (
        <img src={award} alt="" key={idx} />
      ))}
    </div>

    <ButtonBase
      type="transparent"
      size="medium"
      text="home.showAchievements"
      handleClick={console.log}
      endIcon={<VectorArrowSvg className={styles.gradient} />}
    />
  </div>
);

export default Awards;
